export default {
  data() {
    return {
      sortName: '',
      sort: ''
    };
  },
  computed: {
    sortClassName() {
      return (name) => ({
        ascending: this.sortName === name && this.sort === 'ascending',
        descending: this.sortName === name && this.sort === 'descending'
      });
    }
  },
  methods: {
    sortable(name) {
      if (this.sortName === name) {
        if (this.sort === 'descending') {
          this.sort = '';
        } else if (!this.sort) {
          this.sort = 'ascending';
        } else {
          this.sort = 'descending';
        }
      } else {
        this.sort = 'ascending';
      }
      this.sortName = name;
    }
  }
};
