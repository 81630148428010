import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取达人信息
export function getPhilosopher(language) {
  return request({
    url: `/travels/philosopher/details/${language}`,
    method: 'get'
  });
}

// 修改达人信息
export function updatePhilosopher(data) {
  return request({
    url: '/travels/philosopher/save',
    method: 'post',
    data
  });
}

// 获取我的游记列表
export function getMyTravelsList(data) {
  return request({
    url: `/travels/list/${language}`,
    method: 'get',
    params: data
  });
}

// 获取我的游记详情
export function getMytravelsDetail(language, travelsId) {
  return request({
    url: `/travels/details/${language}/${travelsId}`,
    method: 'get'
  });
}

// 保存游记
export function saveTravels(data) {
  return request({
    url: '/travels/save',
    method: 'post',
    data
  });
}

// 删除游记
export function deleteTravels(travelsId) {
  return request({
    url: `/travels/delete/${travelsId}`,
    method: 'post'
  });
}

// 获取游记页面的公共数据
export function getTravelsHomeCommon() {
  return request({
    url: `/travels/home/common/${language}`,
    method: 'get'
  });
}

// 获取游页面记列表
export function getTravelsHomeList(data) {
  return request({
    url: `/travels/home/list/${language}`,
    method: 'get',
    params: data
  });
}

// 获取游页面记详情
export function getTravelsHomeDetail(travelsId) {
  return request({
    url: `/travels/home/travels/details/${language}/${travelsId}`,
    method: 'get'
  });
}

// 获取游记模块达人列表
export function getPhilosopheList(data) {
  return request({
    url: `/travels/home/philosopher/list/${language}`,
    method: 'get',
    params: data
  });
}

// 获取游记模块达人详情
export function getPhilosopheDetail(philosopherId, data) {
  return request({
    url: `/travels/home/philosopher/travels/list/${language}/${philosopherId}`,
    method: 'get',
    params: data
  });
}
