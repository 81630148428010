<template>
  <div class="user-info cl">
    <el-upload class="fl"
               accept="image/*"
               action="/v1/api/common/file"
               :show-file-list="false"
               :on-success="handleAvatarSuccess">
      <avatar :url="photoUrl" :is-self="false" :key="photoUrl"/>
    </el-upload>
    <div class="infos">
      <tabs class="tabs-language" v-model="language">
        <tab-pane v-for="item of languageOptions" :label="item.name" :name="item.value" :key="item.value"></tab-pane>
      </tabs>
      <div class="form-label mt0">{{$t('Pen name')}}</div>
      <div class="form_content">
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="penName"/>
      </div>
      <div class="form-label">{{$t('Introduction')}}</div>
      <div class="form_content">
        <el-input type="textarea"
                  :placeholder="$t('PleaseEnter')"
                  maxlength="1000"
                  v-model="introduction">
        </el-input>
      </div>
      <div class="form-label">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('Update')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Avatar from '../../../components/Avatar';
  import {languageOptions} from '../../../utils/language';
  import {getPhilosopher, updatePhilosopher} from '../../../api/travels';

  export default {
    name: 'AuthorInfo',
    data() {
      return {
        philosopherId: '',
        photoUrl: '',
        penName: '',
        introduction: '',
        languageOptions: languageOptions,
        language: this.$i18n.locale
      };
    },
    components: {Tabs, TabPane, Avatar},
    created() {
      this.getPhilosopher();
    },
    methods: {
      // 获取达人信息
      getPhilosopher() {
        getPhilosopher(this.language).then(res => {
          const {value} = res;
          if (value) {
            this.philosopherId = value.philosopherId;
            this.photoUrl = value.photoUrl;
            this.penName = value.penName;
            this.introduction = value.introduction;
            this.$emit('input', value.philosopherId);
          }
        });
      },
      handleAvatarSuccess(res, file) {
        const {code, value} = res;
        if (code === 200) {
          this.photoUrl = value.url;
        }
      },
      onSubmit() {
        const data = {
          philosopherId: this.philosopherId,
          photoUrl: this.photoUrl,
          penName: this.penName,
          introduction: this.introduction,
          languageType: this.language
        };

        if (!data.penName || !data.introduction) {
          this.$message.warning('请先完善资料再提交');
          return false;
        }

        updatePhilosopher(data).then(res => {
          this.$message.success('更新成功');
          this.getPhilosopher();
        });
      }
    },
    watch: {
      language: function () {
        this.getPhilosopher();
      }
    }
  };
</script>
<style scoped lang="less">
  .user-info{
    padding-bottom:24px;border-bottom:1px dashed #ff6f61;
    .infos{margin-left:120px;}
    .form-label{
      margin-top:16px;
      &.mt0{margin-top:0;}
    }
    .form_content{margin-top:8px;}
    .ipt{
      width:100%;height:40px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:100%;vertical-align:top;}
    /deep/ .avatar{width:90px;height:90px;}
    /deep/ .el-textarea .el-textarea__inner{height:120px;padding:10px;}
  }
  .tabs-language{
    margin-bottom:20px;overflow:hidden;
    /deep/ .tab-nav{margin-top:0;}
  }
</style>
