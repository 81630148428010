<template>
  <div class="main">
    <author-info v-model="philosopherId"/>
    <div class="cl">
      <a class="fl btn-add" @click="toAddTravels">
        <img class="ico" src="../../assets/images/icon/plus-circle.png"/>
        <span>{{$t('Add new travel')}}</span>
      </a>
    </div>
    <div class="table">
      <table>
        <thead>
        <tr>
          <th>{{$t('Title')}}</th>
          <th class="is-sortable" :class="sortClassName('date')" @click="sortable('date')">
            <span class="vam">{{$t('Edit Date')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th>{{$t('Status')}}</th>
          <th class="is-sortable" :class="sortClassName('browse')" @click="sortable('browse')">
            <span class="vam">{{$t('Views')}}</span>
            <span class="caret-wrap">
                <i class="icons sort-caret i-ascending"></i>
                <i class="icons sort-caret i-descending"></i>
              </span>
          </th>
          <th>{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) of list" :key="item.travelsId">
          <td>
            <div class="title">{{item.title}}</div>
          </td>
          <td>{{item.updateDate|formatDate('yyyy/MM/dd')}}</td>
          <td>{{item.isStatus|dictName('travelsStatus')}}</td>
          <td>{{item.viewingCount}}</td>
          <td>
            <el-dropdown trigger="click" @command="toEdit">
              <div>
                <a class="btn-item" href="javascript:;">
                  <img src="../../assets/images/icon/pen.png"/>
                </a>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="lang of languageOption"
                                  :command="{language:lang.value,travelsId:item.travelsId}"
                                  :key="lang.value">
                  {{lang.name}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <a class="btn-item btn-delete" href="javascript:;" @click="deleteTravels(index,item.travelsId)">
              <img src="../../assets/images/icon/ashcan.png"/>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import table from '../../mixins/table';
  import Empty from '../../components/Empty';
  import AuthorInfo from './components/AuthorInfo';
  import {getMyTravelsList, deleteTravels} from '../../api/travels';
  import {languageOptions} from '../../utils/language';
  import {scrollToY} from '../../utils';

  export default {
    name: 'MyTravels',
    data() {
      return {
        list: [],
        totalPages: 0,
        philosopherId: '',
        isEmpty: false,
        languageOption: languageOptions
      };
    },
    components: {Empty, AuthorInfo},
    mixins: [table],
    created() {
      this.getMyTravelsList();
    },
    methods: {
      // 跳转到新增游记页面
      toAddTravels() {
        if (this.philosopherId) {
          this.$router.push({name: 'EditTravels', query: {philosopherId: this.philosopherId}});
        } else {
          this.$message.warning('请先完善信息');
          scrollToY();
        }
      },
      // 跳转到编辑页面
      toEdit(command) {
        this.$router.push({
          name: 'EditTravels',
          query: {id: command.travelsId, philosopherId: this.philosopherId, language: command.language}
        });
      },
      // 获取我的游记列表
      getMyTravelsList(page = 1) {
        const data = {page, perPage: 10};
        getMyTravelsList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getMyTravelsList(page);
      },
      // 删除游记
      deleteTravels(index, travelsId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteTravels(travelsId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.list.splice(index, 1);
                this.$message.success('刪除成功!');
                if (!this.list.length) {
                  this.getMyTravelsList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../assets/style/table";
  .main{
    /deep/ .tab-nav{
      .item{
        width:auto;margin-right:36px;
        &:last-child{margin-right:0;}
        &:after{width:auto;right:0;}
        a{min-width:auto;}
      }
    }
    .btn-delete{margin-left:18px;}
  }
  .btn-add{
    display:block;margin-top:20px;padding:0 15px;line-height:44px;font-size:0;background-color:#ff6f61;border-radius:4px;
    .ico{vertical-align:middle;}
    span{display:inline-block;margin-left:12px;vertical-align:middle;color:#fff;font-size:18px;}
  }
  .table{margin-top:20px;}
</style>
